<template>
  <div>
    <BaseLoading
      style="position: fixed; z-index: 3; top: 50%; right: 50%"
      v-if="is_loading"
    ></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-7 table-responsive">
          <table
            class="table table-stripped text-left bg-light"
            style="font-size: 13px !important"
          >
            <tr>
              <td>Topic</td>
              <td>: {{ order.title || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Type of Paper</td>
              <td>: {{ order.document_type_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Paper Details</td>
              <td colspan="2" style="padding: 4px !important">
                <pre style="padding: 4px !important"> 
                  <span v-html="order.description"></span>
                </pre>
              </td>
            </tr>
            <tr>
              <td>Paper Format / Style</td>
              <td>: {{ order.style_text || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Date Ordered</td>
              <td>: {{ order.date_placed || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Order Deadline</td>
              <td
                :class="
                  order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                : {{ order.deadline.date || "" }}
              </td>
              <td
                :class="
                  order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.deadline.past_due ? "Elapsed" : "" }}
                <span class="font-weight-black">{{ order.deadline.ellapsed || "" }}</span>
              </td>
            </tr>
            <tr>
              <td>Urgency</td>
              <td>
                <span
                  v-if="order.deadline"
                  :class="order.past_due ? 'text-danger' : 'text-success'"
                  >: {{ order.urgency_text_hrs || "" }}</span
                >
              </td>
              <td></td>
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>: {{ order.no_sources || "0" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>: {{ order.writer_level_desc || "0" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr
                    v-for="(item, index) in order.additional_services_text"
                    :key="index + 'add'"
                  >
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td></td>
            </tr>

            <tr>
              <td>Spacing</td>
              <td>: {{ order.spacing_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Number of Page(s)</td>
              <td>
                : {{ order.pages || "" }} / Aprox
                {{ no_of_words || "275" }} words
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Number of Slide(s)</td>
              <td>: {{ order.slides || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td>: {{ order.charts || "" }}</td>
              <td></td>
            </tr>

            <tr
              v-for="(extra, index) in order.extras"
              :key="index + 'oextras'"
              :class="extra.paid !== '0' ? 'alert-success' : 'alert-danger'"
            >
              <td>{{ extra.name || "" }}</td>
              <td>: ${{ extra.cost || 0 }}</td>
              <td>
                <button
                  v-if="extra.paid !== '0'"
                  class="ml-1 btn btn-sm bg-teal"
                >
                  Paid
                </button>
                <span
                  v-else
                  class="d-flex justify-content-center align-items-center"
                >
                  Unpaid
                  <button
                    @click="deleteExtra(extra.id)"
                    class="mx-1 btn btn-sm btn-outline-danger"
                  >
                    Delete
                  </button>
                </span>
              </td>
            </tr>
            <tr class="alert alert-primary border-0">
              <td class="font-weight-bold">Extras Total</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.extras_total || 0 }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Total Order Cost</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.cost }}</td>
              <td></td>
            </tr>
            <tr class="alert alert-success border-0">
              <td class="font-weight-bold">Paid</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.total_paid || 0 }}</td>
              <td></td>
            </tr>
            <tr class="alert alert-danger border-0">
              <td class="font-weight-bold">Balance</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.balance || 0 }}</td>
              <td></td>
            </tr>
          </table>
        </div>

        <div class="col-12 col-sm-5">
          <h4 class="font-weight-bold mt-2">Add Services to Your Paper</h4>
          <div class="alert alert-info">
            <strong>Note: </strong> Additional services will apply once you pay
            for them!
          </div>
          <div class="row">
            <div
              v-for="(item, index) in additionalItems"
              :key="index"
              class="col-6 py-3"
            >
              <p
                class="font-weight-bold text-center is-additional"
                @click="activeComponent = item"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <img width="80px" :src="item.icon || ''" />
                <br />
                <strong>{{ item.title || "" }}</strong>
              </p>
            </div>

            <!-- <div class="col-12">
              <div v-if="order.is_paid">
                <div class="media alert alert-success">
                  <div class="mr-3">
                    <a
                      href="#"
                      class="btn bg-transparent border-success text-success rounded-round border-2 btn-icon"
                    >
                      <i class="icon-checkmark3"></i>
                    </a>
                  </div>

                  <div class="media-body">
                    Your order is paid!
                    <div class="text-muted">We've received your order payment!</div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
  },
  computed: {
    ...mapGetters("setups", ["number_of_pages", "spacing"]),
    no_of_words() {
      return (
        275 *
          this.order.no_pages *
          this.spacing.find((x) => x.id == this.order.spacing).factor || 1
      );
    },
  },
  data() {
    return {
      is_loading: false,
      activeComponent: {},
      additionalItems: [
        {
          title: "Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        },
        {
          title: "Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        },
        {
          title: "Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        },
        {
          title: "Shorten Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        },
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        },
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],
    };
  },

  methods: {
    ...mapActions("order", ["deleteExtras"]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  padding:  8px !important;
  max-width: 300px;
}
.is-additional {
  cursor: pointer;
  img {
    margin: 4px;
  }
  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>
